<template>
  <Dropdown class="dropdown-wrapper" :class="{ 'dropdown-wrapper--no-border': noBorder }" />
</template>

<script>
import Dropdown from '@/components/ui/Dropdown';

export default {
  name: 'DropdownWrapper',

  components: {
    Dropdown,
  },

  props: {
    noBorder: {
      type: Boolean,
      default: false
    },
  },
}
</script>

<style scoped>
.dropdown-wrapper.dropdown-wrapper--no-border {
  @apply border-none;
}

.dropdown-wrapper:deep(.dropdown__input.dropdown__input--value::placeholder) {
  @apply text-graphite-900;
}

.dropdown-wrapper:deep(.dropdown__menu) {
  border-top-width: 1px;
}

.dropdown-wrapper:deep(.dropdown__item--selected),
.dropdown-wrapper:deep(.dropdown__item--selected:not(.dropdown__item--active)) {
  @apply text-purple-350 bg-purple-100;
}

.dropdown-wrapper:deep(.dropdown__item:hover),
.dropdown-wrapper:deep(.dropdown__item--active) {
  @apply text-purple-350 bg-purple-300;
}
</style>