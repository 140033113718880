<template>
  <ReportBase title="Custom Responses by Customer" :is-loading="loadingContent" class="custom-responses">
    <template #afterTitle>
      <DropdownWrapper class="custom-responses__dropdown" name="contentOptions" :options="contentOptions"
        v-model="currentContent" :searchable="false" no-clear no-border />
    </template>

    <template #content>
      <div v-if="currentContent === 'CUSTOM_RESPONSES'" class="h-full">
        <TableContent :columnDefs="tableContent.columnDefs" :defaultColDef="tableContent.defaultColDef"
          :rowData="tableContent.rowData" />
      </div>

      <div v-if="currentContent === 'TOTAL_RESPONSES'" class="flex flex-wrap gap-x-3 gap-y-4">
        <div v-for="data in totalResponses" class="custom-responses__data-block">
          <h3 class="font-bold text-black">{{ data.title }}</h3>

          <div class="mt-2">
            <div v-for="content in data.content" class="flex justify-between">
              <p class="text-purple-800">{{ content.title }}</p>
              <p class="text-purple-500 font-medium">{{ content.value }}</p>
            </div>
          </div>
        </div>
      </div>
    </template>
  </ReportBase>

</template>

<script>
import NotifyMixin from '@/mixins/NotifyMixin';
import CubeRequestMixin from "@/components/report/common/CubeRequestMixin";

import DropdownWrapper from '@/components/report/common/DropdownWrapper.vue';
import ReportBase from '@/components/report/tabular/ReportBase';
import TableContent from '@/components/report/tabular/TableContent.vue';
import { getKeyValue, responseToTable } from '@/components/report/tabular/cubeDataHandler';

export default {
  components: {
    TableContent,
    ReportBase,
    DropdownWrapper,
  },

  mixins: [NotifyMixin, CubeRequestMixin],

  data() {
    return {
      contentOptions: [
        {
          value: 'Custom Responses',
          key: 'CUSTOM_RESPONSES'
        },
        {
          value: 'Total Responses',
          key: 'TOTAL_RESPONSES'
        },
      ],
      currentContent: null,
      loadingContent: false,
      totalResponses: [],
      tableContent: null,
    }
  },

  watch: {
    currentContent(contentToShow) {
      if (contentToShow === 'TOTAL_RESPONSES') {
        this.fetchTotalResponses();
        return;
      }

      this.fetchCustomResponses();
    },
  },

  methods: {
    async fetchTotalResponses() {
      this.loadingContent = true;

      try {
        const response = await this.fetchCubeData('DHR02_1');

        this.handleTotalResponses(response);

        this.loadingContent = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    async fetchCustomResponses() {
      this.loadingContent = true;

      try {
        const response = await this.fetchCubeData('DHR02_2');

        this.tableContent = responseToTable(response);

        this.loadingContent = false;
      } catch (error) {
        this.notifyError(error.message);
      }
    },

    handleTotalResponses(content) {
      const data = content.data.reduce((acc, item) => {
        acc.push({
          title: getKeyValue(item, 'cCustomerName'),
          content: [
            {
              title: 'Total Responses Created',
              value: getKeyValue(item, 'totalCreated')
            },
            {
              title: 'Total Active',
              value: getKeyValue(item, 'totalActive')
            },
          ],
        });
        return acc;
      }, []);
      this.totalResponses = data;
    },
  },

  mounted() {
    this.currentContent = 'CUSTOM_RESPONSES';
  },
};
</script>

<style scoped>
.custom-responses .custom-responses__dropdown {
  @apply w-auto pl-3 pt-0 pb-0;

  padding-top: 2px;
}

.custom-responses .custom-responses__data-block {
  @apply border-graphite-500 px-4 py-3 flex-1;
  border-width: 1px;
  border-radius: 1px;
  min-width: 25%;
  font-size: 13px;
}
</style>